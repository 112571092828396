import md5 from 'js-md5';

// libs
// import * as _api from './lib/api';
import * as _state from '../lib/state';
import * as _transaction from '../lib/transaction';

export default {
    data() {
        return {
            items: [],
            items_handled: [],
            item: '',
            item_index: 0,
            item_composed: false,
            step: '',
            quantity: 1,
            show_modal: false,
            variant: null,
            variants: [],
            addons: [],
            kitchen_groceries: [],
            errors: {
                openprice: false,
                variants: false,
                addons: [],
                kitchen_groceries: [],
            },
        };
    },
    methods: {
        variantSelect(variant) {
            this.variant = variant;

            this.confirmVariant();
        },

        nextStep(from) {
            // let steps = {
            //     variants: 'addons',
            //     variants: 'addons',
            // }
            //             console.log(from);
            //             console.log(this.item);
            if (from == 'details') {
                if (this.item.attributes.variants.length > 0) {
                    this.step = 'variants';
                } else {
                    this.nextStep('variants');
                }
            } else if (from == 'variants') {
                if (this.item.attributes.addons.length > 0) {
                    this.step = 'addons';
                } else {
                    this.nextStep('addons');
                }
            } else if (from == 'addons') {
                if (this.item.attributes.kitchen_groceries.length > 0) {
                    this.step = 'kitchen_groceries';
                } else {
                    this.nextStep('kitchen_groceries');
                }
            } else if (from == 'kitchen_groceries' || from == false) {
                this.item_index++;

                // set data to item
                this.item.quantity = this.quantity;
                this.item.kitchen_groceries = this.kitchen_groceries;
                this.item.addons = this.addons;
                this.item.variant = this.variant;

                // add item to handled items
                this.items_handled.push(this.item);

                // go to next item
                this.nextItem();
            }
        },

        confirmVariant() {
            this.errors.variants = false;

            if (!this.variant) {
                this.errors.variants = true;
                return;
            }

            this.nextStep('variants');
        },

        confirmAddons() {
            // todo own function
            // errors in addons

            let addons = [];
            // let self = this
            let quantity_error = false;

            this.errors.addons = [];

            this.addons.forEach(function (addon_group) {
                // let required = addon_group.required
                let quantity = 0;

                addon_group.options.forEach(function (addon) {
                    addon.error = false;

                    if (addon.quantity > 0) {
                        quantity += parseFloat(addon.quantity);
                        addons.push(addon);
                    }

                    if ((addon.quantity_min && addon.quantity < addon.quantity_min) || (addon.quantity_max && addon.quantity > addon.quantity_max)) {
                        addon.error = quantity_error = true;
                    }
                });

                if (
                    (addon_group.quantity_min && quantity < addon_group.quantity_min) ||
                    (addon_group.quantity_max && quantity > addon_group.quantity_max)
                ) {
                    addon_group.error = quantity_error = true;
                }
                // if (required && quantity == 0) {
                //     self.errors.addons.push(addon_group.description)
                // }
            });

            if (this.errors.addons.length > 0 || quantity_error === true) {
                return;
            }

            this.addons = addons;

            this.nextStep('addons');
        },

        confirmKitchenGroceries() {
            // let kitchen_groceries = []
            let self = this;

            // reset errors
            this.errors.kitchen_groceries = [];

            this.item.attributes.kitchen_groceries.forEach(function (line) {
                let found = false;
                self.kitchen_groceries.forEach(function (selected) {
                    if (selected.description == line.description) {
                        found = true;
                    }
                });

                if (!found) {
                    self.errors.kitchen_groceries.push(line.description);
                }
            });

            if (this.errors.kitchen_groceries.length > 0) {
                return;
            }

            this.nextStep('kitchen_groceries');
        },

        // Kitchen Groceries functions
        setKitchenGrocery(description, value) {
            // eslint-disable-next-line no-unused-vars
            let found = false;
            this.kitchen_groceries.forEach(function (line) {
                if (line.description == description) {
                    found = true;
                    line.value = value;
                }
            });

            if (!found) {
                this.errors.kitchen_groceries.splice(this.errors.kitchen_groceries.indexOf(description, 0), 1);
                this.kitchen_groceries.push({ description: description, value: value });
            }
        },

        isKitchenGrocerySelected(description, value) {
            let selected = false;
            this.kitchen_groceries.forEach(function (line) {
                if (line.description == description && line.value == value) {
                    selected = true;
                }
            });
            return selected;
        },

        // check if field has error
        hasError(field, value) {
            let has_error = false;
            this.errors[field].forEach(function (error) {
                if (error == value) {
                    has_error = true;
                }
            });
            return has_error;
        },

        addonRadioSelect(addon, option) {
            addon.options.forEach((addon_option) => {
                if (option.id == addon_option.id) {
                    addon_option.quantity = this.quantity;
                } else {
                    addon_option.quantity = 0;
                }
            });
        },

        changeAddonQuantity(addon_option, quantity, addon) {
            let totalAddons = addon.options.reduce((sum, li) => sum + li.quantity, 0);
            // add check for maximum total quantities
            if (quantity == 1 && totalAddons == addon.quantity_max) {
                return;
            }

            // add check for maximum on individual addon option
            if (quantity == 1 && addon_option.quantity >= addon_option.quantity_max && addon_option.quantity_max !== 0) {
                return;
            }

            // don't go below zero
            if (addon_option.quantity + quantity < 0) {
                return;
            }

            addon_option.quantity = parseFloat(addon_option.quantity) + quantity;
            addon_option.quantity_init = addon_option.quantity;
        },

        // addItem(quantity = 1) {
        addItem(showDetails = false) {
            console.log('initial', this.initialitem);

            this.item_index = 0;
            this.item_composed = false;
            this.items = [];
            this.items_handled = [];
            this.quantity = this.initialquantity > 0 ? this.initialquantity : 1;

            // clone initial item
            let initialitem = JSON.parse(JSON.stringify(this.initialitem));

            // return early when not available in inventory
            if (!initialitem.attributes.available_in_inventory) {
                return;
            }

            // check for composed items
            if (initialitem.attributes.composeditems.data.length > 0) {
                this.items = initialitem.attributes.composeditems.data;
                this.item_composed = true;
            } else {
                this.items.push(initialitem);
            }
            // select het next item in the array
            this.nextItem(showDetails);
        },

        addItemByScannedBarcode(item) {
            this.item_index = 0;

            this.items = [];
            this.items_handled = [];
            this.quantity = 1;
            this.items.push(item);

            this.nextItem();

            // _transaction.updateDefaultItemsFee();
        },

        nextItem(showDetails = false) {
            // check if there is a next item
            // when there is a next item handle the item
            if (this.items[this.item_index]) {
                this.handleItem(this.items[this.item_index], showDetails);

                return;
            }
            // when no item is next, set the items to the cart
            else {
                let self = this;
                let line_id = null;

                if (this.item_composed) {
                    let initialitem = JSON.parse(JSON.stringify(this.initialitem));
                    initialitem.quantity = this.quantity;
                    initialitem.variant = null;
                    initialitem.addons = [];

                    line_id = this.addItemToCart(initialitem);
                }

                // loop through the handled items
                this.items_handled.forEach(function (item) {
                    // set parent_line_id
                    if (line_id) {
                        item.line_parent_id = line_id;
                    }

                    // set composed_child
                    item.composed_child = self.item_composed;

                    self.addItemToCart(item);
                });

                // close the modal
                this.closeModal();
            }
        },

        handleItem(item, showDetails = false) {
            // set item
            this.item = item;

            // reset the values and errors
            this.variant = null;
            this.kitchen_groceries = [];
            this.addons = JSON.parse(JSON.stringify(this.item.attributes.addons));

            this.errors = {
                addons: [],
                kitchen_groceries: [],
            };

            // open modal?
            if (
                this.item.attributes.pos_openprice == true ||
                this.item.attributes.addons.length > 0 ||
                this.item.attributes.kitchen_groceries.length > 0 ||
                this.item.attributes.variants.length > 0 ||
                showDetails
            ) {
                this.openModal(showDetails);
                // openmodal = true
                return;
            }

            // force to end of the wizard
            this.nextStep(false);
        },

        addQuantity() {
            this.quantity = this.quantity + 1;

            // add addons
            this.addons.forEach((addon) => {
                // addons has only options when addons not yet confirmed
                if (addon.options) {
                    addon.options.forEach((option) => {
                        if (option.quantity > 0) {
                            option.quantity = parseFloat(option.quantity) + parseFloat(option.quantity_init ? option.quantity_init : 1);
                        }
                    });
                } else {
                    if (addon.quantity > 0) {
                        addon.quantity = parseFloat(addon.quantity) + parseFloat(addon.quantity_init ? addon.quantity_init : 1);
                    }
                }
            });
        },
        subQuantity() {
            if (this.quantity > 1) {
                this.quantity = this.quantity - 1;

                // subtract addons
                this.addons.forEach((addon) => {
                    // addons has only options when addons not yet confirmed
                    if (addon.options) {
                        addon.options.forEach((option) => {
                            if (option.quantity > 0) {
                                option.quantity =
                                    parseFloat(option.quantity) - parseFloat(option.quantity_init ? option.quantity_init : this.quantity);
                            }
                        });
                    } else {
                        if (addon.quantity > 0) {
                            addon.quantity = parseFloat(addon.quantity) - parseFloat(addon.quantity_init ? addon.quantity_init : this.quantity);
                        }
                    }
                });
            }
        },

        async addItemToCart(item) {
            let id = Math.random().toString(22).substr(2, 22);
            // let self = this

            // set the rate
            let rate = 0;
            if (!item.composed_child) {
                rate = item.variant ? item.variant.attributes.price : item.attributes.webshop_price;
            }

            // create data object for line/item
            let data = {
                parent_id: item.line_parent_id ? item.line_parent_id : null,
                item_id: item.id,
                item_parent_id: item.parent_id,
                type: 'revenue',
                addon: false,
                composed_child: item.composed_child ? item.composed_child : false,
                barcode: item.attributes.barcode,
                description: item.attributes.description + (item.variant ? ' - ' + item.variant.attributes.label : ''),
                thumbnail: item.attributes.thumbnail_url,
                taxrate: item.attributes.taxrate,
                rate: rate,
                kitchen_groceries: item.kitchen_groceries,

                elockers_mode: item.attributes.elockers_mode,
                elockers_locker_id: item.attributes.elockers_locker_id,
                elockers_active: item.attributes.elockers_active,
                elockers_type: item.attributes.elockers_type,

                variant_id: item.variant ? item.variant.id : null,
                variant_label: item.variant ? item.variant.attributes.label : null,
            };

            // add addons
            let data_addons = [];

            item.addons.forEach(function (addon) {
                data_addons.push({
                    quantity: parseInt(addon.quantity),
                    item_id: addon.item_id,
                    item_parent_id: addon.item_parent_id,
                    type: 'revenue',
                    addon: true,
                    barcode: addon.barcode,
                    description: addon.description,
                    thumbnail: null,
                    taxrate: addon.taxrate,
                    rate: addon.price,
                    kitchen_groceries: [],
                });
            });

            // parent_id: data.id, (data_addon.id = md5(JSON.stringify(data_addon)));
            // data_addon.quantity = parseInt(addon.quantity);

            // data_addons.push(data_addon);

            // add the addon to the transaction
            // _transaction.addItem(data_addon);
            data.addon_ids = md5(JSON.stringify(data_addons));
            data.id = md5(JSON.stringify(data));
            data.quantity = item.quantity;

            let res = await _transaction.addItem(data);
            console.log('res', res);

            let basket = _state.get('transaction/getTransaction');

            // add linkeditems
            if (item.attributes.linkeditems && item.attributes.linkeditems.webshop) {
                if (basket.lines.filter((line) => line.id == data.id).length > 0) {
                    item.attributes.linkeditems.webshop.forEach(function (linkeditem) {
                        let data_linkeditem = {
                            parent_id: data.id,
                            item_id: linkeditem.id,
                            item_parent_id: linkeditem.attributes.item_parent_id,
                            type: 'revenue',
                            addon: true,
                            barcode: linkeditem.attributes.barcode,
                            description: linkeditem.attributes.description,
                            thumbnail: null,
                            taxrate: linkeditem.attributes.taxrate,
                            rate: linkeditem.attributes.webshop_price ? linkeditem.attributes.price : 0,
                            kitchen_groceries: [],
                        };
                        data_linkeditem.id = md5(JSON.stringify(data_linkeditem));
                        data_linkeditem.quantity = parseInt(data.quantity);

                        if (!basket.lines.filter((line) => line.id == data_linkeditem.id).length) {
                            _transaction.addItem(data_linkeditem);
                        }
                    });
                }
            }

            data_addons.forEach(function (addon) {
                addon.id = md5(JSON.stringify(addon));

                addon.parent_id = data.id;

                // add addon to cart if not yet in there.
                const itemsAlreadyInCart = basket.lines.filter((line) => line.id == addon.id);
                if (!itemsAlreadyInCart.length) {
                    _transaction.addItem(addon);
                }
            });

            this.$emit('itemadded');

            return id;
        },

        openModal(show_details = true) {
            // this.resetValues();

            if (show_details == false) {
                if (this.item.attributes.variants.length > 0) {
                    this.step = 'variants';
                } else {
                    this.nextStep('variants');
                }
            } else {
                this.step = 'details';
            }

            document.body.classList.add('modal-open');

            // open modal
            this.show_modal = true;
        },

        closeModal() {
            // this.resetValues();

            document.body.classList.remove('modal-open');

            // close modal
            this.show_modal = false;
        },
    },
};
